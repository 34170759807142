<template>
  <div
    class="modal-overlay"
    @click.stop="$emit('close-modal-task')"
  >
    <div
      class="modal-container"
      :style="{cursor: 'default'}"
    >
      <el-col
        v-if="task"
        class="modal"
        @click.native.stop
      >
        <el-col class="column-wrap">
          <div class="window-module">
            <div class="column-title">
              Title
            </div>
            <el-input
              v-model="task.title"
              type="textarea"
              class="textarea"
              autosize
              placeholder="Task title"
              @blur="task.update()"
            />
            <p v-if="!column.isArchive">
              in list "{{ column.title }}"
            </p>
            <div
              v-else
              class="archived-item"
            >
              <div class="column-title">
                This card is archived.
              </div>
            </div>
          </div>
        </el-col>

        <el-row class="column-wrap">
          <el-col class="window-module">
            <div class="column-title">
              Options
            </div>
            <div class="modal-body">
              <div class="option-item">
                <p class="option-item-title">
                  Priority
                </p>
                <priority
                  :priority="task.priority"
                  @change-status="changePriority"
                />
              </div>

              <div class="option-item">
                <p class="option-item-title">
                  Due date
                </p>
                <div class="checkbox-wrap">
                  <input
                    v-model="task.completed"
                    type="checkbox"
                    :title="dateTitle"
                    class="date-checkbox"
                    @change="task.update()"
                  >
                  <div :title="dateTitle">
                    <div class="block">
                      <el-date-picker
                        v-model="task.dueDate"
                        type="datetime"
                        size="small"
                        placeholder="Select date and time"
                        format="yyyy-MM-dd  HH:mm"
                        :picker-options="pickerOptions"
                        @change="task.update()"
                      />
                    </div>
                  </div>

                  <el-button
                    v-if="overdueTime && !task.completed"
                    class="status-date"
                    type="warning"
                    size="small"
                  >
                    OVERDUE
                  </el-button>
                  <el-button
                    v-if="task.completed"
                    type="success"
                    size="small"
                  >
                    DONE
                  </el-button>
                </div>
              </div>

              <div class="option-item">
                <p class="option-item-title">
                  Users
                </p>
                <users-avatars
                  :size="'large'"
                  :add-button="true"
                  :hidden-members="false"
                  :users="task.users"
                  @update-users="updateUsers"
                />
              </div>

              <div
                v-if="task.tags"
                class="option-item"
              >
                <p class="option-item-title">
                  Tags
                </p>
                <kanban-tags
                  :tags="task.tags"
                  :size="'small'"
                  :add-btn="true"
                />
              </div>
            </div>
          </el-col>
        </el-row>

        <el-col class="column-wrap">
          <div class="window-module">
            <div class="column-title">
              Description
            </div>
          </div>
          <el-input
            v-model="task.description"
            type="textarea"
            class="textarea"
            autosize
            placeholder="Add a more detailed description..."
            @blur="task.update()"
          />
        </el-col>

        <el-col class="column-wrap">
          <div class="window-module">
            <div class="column-title">
              Attachment
            </div>
            <el-upload
              v-if="fileList"
              class="upload-demo"
              action="#"
              multiple
              :auto-upload="false"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :file-list="fileList"
              list-type="picture"
            >
              <el-button
                size="small"
                type="primary"
                plain
              >
                Click to upload
              </el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >
                jpg/png files with a size less than 500kb
              </div>
            </el-upload>
          </div>
        </el-col>

        <el-col class="column-wrap">
          <div class="window-module">
            <div class="column-title">
              Checklist
            </div>

            <div class="progress-wrap">
              <progress-bar
                :width="14"
                :show-progress="true"
                :percentage="percentageChecklist"
              />
            </div>

            <div class="checklist-items">
              <div
                v-for="item of task.subtasks"
                :key="item.uuid"
                class="checklist-one-item"
              >
                <input
                  v-model="item.checked"
                  type="checkbox"
                  class="date-checkbox"
                  @change="item.save()"
                >
                <div class="checklist-item-title">
                  {{ item.title }}
                </div>
                <el-button
                  icon="el-icon-delete"
                  size="mini"
                  circle
                  :style="{ border: 'none' }"
                  title="Delete item"
                  @click.prevent="deleteChecklistItem(item)"
                />
              </div>

              <div class="description-wrap">
                <el-input
                  v-model="newSubtaskTitle"
                  type="textarea"
                  class="textarea"
                  autosize
                  placeholder="Add an item"
                  @keydown.enter.native.prevent="addNewSubtask"
                />
                <el-button
                  type="primary"
                  size="small"
                  @click="addNewSubtask"
                >
                  Add a subtask
                </el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-col>
    </div>
  </div>
</template>

<script>
import { Subtask } from '@timeragent/core';

export default {
  name: 'TaskModalWindow',
  components: {
    UsersAvatars: () => import('./UsersAvatars.vue'),
    ProgressBar: () => import('./ProgressBar.vue'),
    KanbanTags: () => import('./KanbanTags.vue'),
    Priority: () => import('./Priority.vue'),
  },
  props: {
    task: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    column: {
      type: Object,
      required: true,
      default() {},
    },
  },
  data() {
    return {
      fileList: this.task?.picture || [],
      newSubtaskTitle: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
    };
  },
  computed: {
    dateTitle() {
      if (!this.task.completed) {
        return 'This card is due later';
      }

      return 'This card is completed';
    },
    percentageChecklist() {
      const { items } = this.task.subtasks;

      if (!items.length) {
        return 0;
      }

      const checked = items.filter(item => item.checked === true);

      return +((100 * checked.length) / items.length).toFixed(0);
    },
    overdueTime() {
      if (!this.task.dueDate) {
        return false;
      }
      const time = new Date(this.task.dueDate) - new Date().getTime();

      return (time <= 0);
    },
  },
  methods: {
    async addNewSubtask() {
      if (!this.newSubtaskTitle) {
        return;
      }

      const subtask = new Subtask({
        title: this.newSubtaskTitle,
        taskUuid: this.task.uuid,
      });
      const sub = await subtask.create();

      this.newSubtaskTitle = '';
      this.task.subtasks.push(sub);
    },
    async deleteChecklistItem(sub) {
      await sub.delete();

      this.task.subtasks = this.task.subtasks.filter(t => t.uuid !== sub.uuid);
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      this.task.picture = fileList;
    },
    handleRemove(file, fileList) {
      this.handleChange(file, fileList);

      if (fileList.length === 0) {
        this.task.picture = null;
      }
    },
    updateUsers($event) {
      this.task.users = $event;
      this.task.update();
    },
    changePriority($event) {
      this.task.priority = $event;
      this.task.update();
    },
  },
};
</script>

<style lang="stylus"
       rel="stylesheet/css"
       scoped
>
::v-deep {
  .el-select .el-input__inner {
    background: transparent;
    border: none;
    color: white;
  }

  .el-select {
    border-radius: 4px;
  }

  .el-icon-arrow-up:before {
    color: white;
  }

  .el-upload-list {
    display: flex;
    flex-wrap: wrap;
    justify-content space-between
  }

  & li {
    max-width 49%
  }
}

.modal-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.modal-container {
  display: flex;
  justify-content: center;
  max-height: 100vh;
  overflow: auto;
}

.modal {
  position: relative;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  margin: 4% 0;
  padding: 30px;
  min-width: 650px;
  width: 50%;
  min-height: 200px;
  border-radius: 4px;
  background: #ffffff;
  z-index: 101;
  overflow: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.column-wrap {
  position: relative;

  &:last-child {
    padding-bottom: 30px;
  }
}

.column-wrap + .column-wrap {
  padding-top: 20px;
}

.el-date-editor {
  width: 170px;
}

.outside-icon {
  position: absolute;
  left: 0;
  top: 15px;
  font-size: 17px;
}

.modal-body {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
}

.option-item {
  margin-bottom: 10px;
}

.option-item .el-select {
  width: 105px;
}

.option-item-title {
  margin-bottom: 3px;
}

.status-date {
  cursor: default;
}

.textarea {
  padding-bottom: 10px;
}

.archived-item {
  height: 22px;
  background-image: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0.05) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0.05) 75%,
    transparent 75%,
    transparent);
  background-size: 14px 14px;
  padding: 12px 12px 12px 10px;
}

.date-checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.checkbox-wrap {
  display: grid;
  grid-template-columns: max-content auto max-content max-content;
  grid-template-rows: 1fr;
  align-items: center;
  grid-gap: 5px;
}

.column-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.progress-wrap {
  margin-bottom: 6px;
}

.checklist-one-item {
  position: relative;
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-template-rows: 1fr;
  gap: 0 10px;
  align-items: center;
}

.checklist-item-title {
  padding: 5px 0;
  cursor: pointer;
}

.down-btns {
  margin: 0 auto;
  padding: 15px 0 20px 0;
}
</style>
